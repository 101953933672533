/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import StarRatings from 'react-star-ratings';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';

import Spinner from '../components/spinner';
import { trackPageView } from '../helpers/analytics';
import { refreshToken, getShipmentById, postFeedbackById, getFeedbackById } from '../services/index';

toast.configure();

const npsInitialValues = [
  { id: 0, colour: 'rgb(44,123,229)', message: 'Not Likely' },
  { id: 1, colour: 'rgb(44,123,229)', message: 'Not Likely' },
  { id: 2, colour: 'rgb(44,123,229)', message: 'Not Likely' },
  { id: 3, colour: 'rgb(44,123,229)', message: 'Not Likely' },
  { id: 4, colour: 'rgb(44,123,229)', message: 'Not Likely' },
  { id: 5, colour: 'rgb(44,123,229)', message: 'Not Likely' },
  { id: 6, colour: 'rgb(44,123,229)', message: 'Not Likely' },
  { id: 7, colour: 'rgb(44,123,229)', message: 'May Be' },
  { id: 8, colour: 'rgb(44,123,229)', message: 'May Be' },
  { id: 9, colour: 'rgb(44,123,229)', message: 'Extremely Likely' },
  { id: 10, colour: 'rgb(44,123,229)', message: 'Extremely Likely' },
];

const Feedback = (props) => {
  const feedback = { rating: 5, comment: '' };

  const schema = Yup.object().shape({});
  const [point, setPoint] = useState('');
  const [rating, setRating] = useState(0);
  const [success, setSuccess] = useState(false);
  const [loading, showSpinner] = useState(false);
  const [nps, setNps] = useState(npsInitialValues);
  const [completed, setCompleted] = useState(false);
  const [pharmacyName, setPharmacyName] = useState('');
  const [token, setToken] = useState(props.match.params.token);

  useEffect(() => {
    document.title = `Feedback`;
    getInitialData();
    updateToken();
    trackPageView();
  }, []);

  const getInitialData = async () => {
    showSpinner(true);
    await getShipment();
    await getFeedback();
    showSpinner(false);
  };

  const getShipment = async () => {
    const response = await getShipmentById(props.match.params.id);
    if (response && response.data) {
      setPharmacyName(response?.data.shipper_name);
    }
  };

  const getFeedback = async () => {
    const response = await getFeedbackById(props.match.params.id);
    if (response) {
      if (response.status === 200 && response.data?.isFeedback) {
        setCompleted(true);
      }
    }
  };

  const updateToken = async () => {
    const response = await refreshToken(token);
    if (response && response.status === 200 && response?.data) {
      setToken(response.data?.token);
    }
  };

  const handleSubmit = async (data) => {
    showSpinner(true);
    data.rating = rating;
    data.nps = point;
    if (data.rating === 0) {
      toast.error('Please rate your delivery experience?');
      showSpinner(false);
    }
    const response = await postFeedbackById(props.match.params.id, token, data);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        showSpinner(false);
        setSuccess(true);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const onPointChange = async (e, item) => {
    e.preventDefault();
    const data = cloneDeep(npsInitialValues);
    await setNps(data);
    const otherPoints = data.filter((nps) => nps.id > item.id);
    const ratedPoints = data.filter((nps) => nps.id <= item.id);
    if (item.id <= 6) {
      ratedPoints.forEach((point) => {
        point.colour = 'rgb(255,102,102)';
      });
    } else if (item.id >= 7 && item.id <= 8) {
      ratedPoints.forEach((point) => {
        point.colour = 'rgb(255,167,38)';
      });
    } else if (item.id >= 9 && item.id <= 10) {
      ratedPoints.forEach((point) => {
        point.colour = 'rgb(124,179,66)';
      });
    }
    otherPoints.forEach((point) => {
      point.colour = 'rgb(203, 211, 227)';
    });
    const updatedNps = [...ratedPoints, ...otherPoints];
    setNps(updatedNps);
    setPoint(item.id);
  };

  const { tenantLogo } = props;

  return (
    <div id='custom-body' className="d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100 bg-primary">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col col-md-6 displaylg displaymd displaysm">
            <div className="card ">
              <div className="card-body">
                <div className="text-center px-5">
                  <img src={tenantLogo} alt="logo" className="img-fluid" />
                </div>
                <Spinner display={loading} logo={tenantLogo}>
                  {!success && !completed && (
                    <div>
                      <h1 className="mt-4">How was your delivery?</h1>
                      <Formik initialValues={feedback} validationSchema={schema} onSubmit={handleSubmit}>
                        {({ handleChange, handleSubmit, values, errors }) => (
                          <form noValidate onSubmit={handleSubmit}>
                            <div className="mt-2">
                              <StarRatings
                                rating={rating}
                                starRatedColor="rgb(246,195,67)"
                                starHoverColor="rgb(246,195,67)"
                                changeRating={(rating) => {
                                  setRating(rating);
                                }}
                                numberOfStars={5}
                                starDimension="36px"
                              />
                            </div>
                            <div className="mt-4  mb-2">
                              <h3>{`How likely are you to recommend ${pharmacyName} to friends or family?`}</h3>
                            </div>
                            <div className="mt-4">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                  {nps.map((item) => {
                                    return (
                                      <li key={item.id}>
                                        <span
                                          style={{ backgroundColor: item.colour, cursor: 'pointer' }}
                                          className="page-link"
                                          onClick={(e) => {
                                            onPointChange(e, item);
                                          }}
                                        >
                                          {item.id}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </nav>
                              <div id="textbox" className="d-flex justify-content-between">
                                <p>{`0= ${nps[0].message}`}</p>
                                <p>{`10= ${nps[9].message}`}</p>
                              </div>
                            </div>
                            <div className="form-group mt-4">
                              <textarea className="form-control" rows="4" name="comment" placeholder="Help us get better with your feedback" value={values.comment} onChange={handleChange} isInvalid={!!errors.comment} />
                              {errors.comment && <div className="text-danger mt-2 ms-1 h5">{errors.comment}</div>}
                            </div>
                            <button type="submit" block className="btn btn-primary">
                              Submit
                            </button>
                          </form>
                        )}
                      </Formik>
                    </div>
                  )}
                  {success && (
                    <div>
                      <h1 className="mt-5">Thank you for the feedback!</h1>
                    </div>
                  )}
                  {completed && (
                    <div>
                      <h3 className="mt-5">Thank you! You have already submitted the feedback.</h3>
                    </div>
                  )}
                </Spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantLogo: state.tenantLogo,
  };
};

export default connect(mapStateToProps)(Feedback);
